import React from "react";
import styled, {keyframes} from "styled-components";
import useWindowDimensions from '../../hooks/useWindowDimensions';

const Wrapper = styled.div`
  z-index: 500;
  position: absolute;
  top: 0px;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
`;

const buildPath = keyframes`
  from {
    stroke-dashoffset: 100;
   
  }

  to {
    stroke-dashoffset: 0;
   
  }
`;

const AnimatedPath =  styled.path`
  animation: ${buildPath} 2s linear infinite;
  stroke-dasharray: 10;
  stroke-dashoffset: 1000;
`

const InfoBox = styled.div`
  z-index:9999;
  position:absolute;
  left:${props => props.position.x}px;
  top:${props => props.position.y}px;
  width:350px;
  
`


const Tooltipp = styled.p`
  font-size:16px;
  font-weight: 600;
  padding: 10px 20px;
  background-color: #fff;
  text-transform: uppercase;
`


const DottedLine = ({ start, end, offset, tooltipp, textPositon = "center" }) => {
  const { height, width } = useWindowDimensions();
  const calcControlPoint = (start, end, offset) => {
      // Source:
      // https://stackoverflow.com/questions/45240401/svg-path-create-a-curvy-line-to-link-two-points
      // https://stackoverflow.com/questions/49274176/how-to-create-a-curved-svg-path-between-two-points/49286885

      // mid-point of line:
      var mpx = (end.x + start.x) * 0.5;
      var mpy = (end.y + start.y) * 0.5;

      // angle of perpendicular to line:
      var theta = Math.atan2(end.y - start.y, end.x - start.x) - Math.PI / 2;

      // location of control point:
      var c1x = mpx + offset * Math.cos(theta);
      var c1y = mpy + offset * Math.sin(theta);
      return {x:c1x,y:c1y}
  }
  const controlPoint = calcControlPoint(start, end,offset)
  
  const getInfoBoxPosition = () => {
  
    switch (textPositon) {
      case "center":
        return {x:controlPoint.x, y:controlPoint.y+100}
        
      case "start":
        return {x:start.x-200, y:start.y-100}
        
      case "end":
        return {x:end.x, y:end.y+100}
      
      default:
        return {x:controlPoint.x, y:controlPoint.y+100}
    }

  }

  return (
    <Wrapper>
      <InfoBox position={getInfoBoxPosition()} pos><Tooltipp>{tooltipp}</Tooltipp></InfoBox>
      <svg viewBox={`0 0 ${width} ${height}`} fill="none" key={Math.random()}>
        {/* <circle cx={controlPoint.x} cy={controlPoint.y} r="4" fill="red"></circle>
        <circle cx={end.x} cy={end.y} r="4" fill="red"></circle> */}
        <circle
          cx={start.x}
          cy={start.y}
          r="20"
          fill="none"
          stroke="black"
          strokeWidth="2"
        ></circle>
        <circle cx={start.x} cy={start.y} r="5" fill="black"></circle>
        <AnimatedPath
          id="curve"
          d={`M ${start.x} ${start.y} Q ${controlPoint.x} ${controlPoint.y} ${end.x} ${end.y}`}
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          fill="transparent"
          
        ></AnimatedPath>
      </svg>
    </Wrapper>
  );
};

export default DottedLine;
