
import FiguretteOnlyImg1 from'./drawings/1820_zeichnung.png'
import FiguretteOnlyImg2 from'./drawings/1830_zeichnung.png'
import FiguretteOnlyImg3 from'./drawings/1890_zeichnung.png'
import FiguretteOnlyImg4 from'./drawings/1930_zeichnung.png'
import FiguretteOnlyButterick from'./drawings/1950_zeichnung.png' 
import FiguretteOnlyMcqueen from'./drawings/alexander_mcqueen_zeichnung.png'
import FiguretteOnlyChanel from'./drawings/chanel_1920_zeichnung.png'
import FiguretteOnlyShuting from'./drawings/shuting_qui_zeichnung.png'
import FiguretteOnlyThebe from'./drawings/thebe_magugu_zeichnung.png'
import FiguretteOnlyTokyo from'./drawings/tokyo_james_zeichnung.png'

  const figurettes =  {
    "frack":FiguretteOnlyImg1,
    "biedermeier": FiguretteOnlyImg2,
    "fahrradkostuem": FiguretteOnlyImg3,
    "knickerbocker":FiguretteOnlyImg4,
    "butterick":FiguretteOnlyButterick,
    "cocochanel":FiguretteOnlyChanel,
    "mcqueen":FiguretteOnlyMcqueen,
    "shutingqiu":FiguretteOnlyShuting,
    "thebemagugu":FiguretteOnlyThebe,
    "tokyojames":FiguretteOnlyTokyo
  }

export default figurettes