import styled from "styled-components";
import React, { useRef, useState, useEffect } from "react";



const ActiveImageWrapper = styled.div`
  user-select: none;   
  width:${(props) =>  props.size.x}px;
  height:${(props) =>  props.size.y}px;
  position: absolute; 
  z-index: ${(props) => props.selected ? "200": "100"};
  top: ${(props) =>   props.startingPosition.y}px;
  left: ${(props) =>   props.startingPosition.x}px;
  &:hover{
    cursor: grab;
  }
`;

const ActiveImage = styled.img`
  width:100%;
  height: 100%;
  object-fit: cover;
`;

const ScaleHandler = styled.div`
  transform: translateY(-50%) translateX(-50%); 
  position:absolute;
  z-index:999;
  height:10px;
  width:10px;
  cursor: nwse-resize;
  background-color:#21C1FC;
  border-radius:50%;
`

const ScaleHandlerTopLeft = styled(ScaleHandler)`
  top:-6px;
  left:-6px;
`

const ScaleHandlerTopRight = styled(ScaleHandler)`
  top:-6px;
  right:-6px;
`

const ScaleHandlerBottomLeft = styled(ScaleHandler)`
  bottom:-6px;
  left:-6px;
`

const ScaleHandlerBottomRight = styled(ScaleHandler)`
  bottom:-6px;
  right:-6px;
`

const CloseIcon = styled.div`
  height:25px;
  width:25px;
  right:15px;
  top:15px;
  position:absolute;
  background-color:white;
  >svg{
    width: 100%;
    height: 100%;
    stroke:#21C1FC;
    stroke-width:4;
    &:hover{
      stroke-width:2.5;
      transform: scale(1.1);
    }
  }
`

const ActivePattern = (props) => {
  const { mode, onSetMode, 
  position, selectedPattern, 
  onImagePointerDown, 
  patternImage, size, 
  onPositionChange, 
  onDeletePress, 
  onTransformation, 
  initialScale,
  initialRotation
  } = props

  const [delta, setDelta] = useState({ x: 0, y: 0})
  const [mouseStartingPoint, setMouseStartingPoint] = useState(0)
  const [rotationDegree, setRotationDegree] = useState(initialRotation)
  const [scale, setScale] = useState(initialScale)
  const [handlerRotationOffset, setHandlerRotationOffset] = useState("")
  const elementRef = useRef();


  useEffect(() => {
    const handlePointerMove = (e) => {
      // get position at click
      const oldX = mouseStartingPoint?.x
      const oldY = mouseStartingPoint?.y

      // get current mous position
      const newX = e.clientX
      const newY = e.clientY

      // calc centerpoint of rectangle
      const centerX =  position.x +  elementRef.current.clientWidth/2
      const centerY =  position.y + elementRef.current.clientHeight/2

      // calculate angle
      const rad = Math.atan2(newX - centerX, - (newY - centerY) ) ;  
      
      if(selectedPattern) {
        if(mode === "drag") {
          setDelta({ x: newX - oldX, y: newY - oldY});
        }
        if(mode === "transform"){
          const newRotationDeg = (rad - handlerRotationOffset) * (180 / Math.PI)
          setRotationDegree(newRotationDeg);  // use local state for instant updates

          const distanceOld = Math.hypot(oldX - centerX, oldY - centerY) // get distance from center to transform handle 
          const distanceNew = Math.hypot(newX - centerX , newY - centerY) // get new distance from center to mouse position
          const scaleRatio = distanceNew / distanceOld // get appropriate scale ration
          const newScale = scale * scaleRatio
          setScale(newScale) // use local state for instant updates
          onTransformation(newScale,newRotationDeg) // save new scale and rotation in context
        }
      }   
    }
  

    
    document.addEventListener('pointermove', handlePointerMove , { passive: true });
    
    return () => {
      // clean up the event handler when the component unmounts
      document.removeEventListener('pointermove', handlePointerMove );
    
    };
  }, [mode])

 
  return (
    <ActiveImageWrapper
      ref={elementRef}
      style={{ transform: `translateY(${delta?.y}px) translateX(${delta?.x}px)  rotate(${rotationDegree}deg) scale(${scale})`}}
      draggable="false"
      startingPosition={position}
      mode={mode}
      selected={selectedPattern}
      size={size}
    >
      <ActiveImage 
        onPointerDown={(e) => {
          onSetMode("drag");
          onImagePointerDown()
          setMouseStartingPoint({
            x:e.clientX,
            y:e.clientY
          })
        }}
        onPointerUp={(e) => {
          onPositionChange({
            x: position.x + delta.x,
            y: position.y + delta.y
          })
          setDelta({ x: 0, y: 0})
        }}
        onDragStart={(e) => { e.preventDefault() }}
        draggable="false" 
        src={patternImage}
      />
      {selectedPattern  && 
        <>
          <ScaleHandlerTopLeft
            style={{ transform: `scale(${1/scale})`}}
            onMouseDown={(e) => {
              onSetMode("transform") 
              setHandlerRotationOffset(-Math.PI/4)
              setMouseStartingPoint({
                x:e.clientX,
                y:e.clientY
                })
            }}
          />
         <ScaleHandlerTopRight
            style={{ transform: `scale(${1/scale})`}}
            onMouseDown={(e) => {
              onSetMode("transform") 
              setHandlerRotationOffset(Math.PI/4)
              setMouseStartingPoint({
                x:e.clientX,
                y:e.clientY
              })
            }}
         
          /> 
          <ScaleHandlerBottomRight
            style={{ transform: `scale(${1/scale})`}}
            onMouseDown={(e) => {
            onSetMode("transform") 
            setHandlerRotationOffset(3/4 * Math.PI)
            setMouseStartingPoint({
              x:e.clientX,
              y:e.clientY
              })
            }}
          />
          <CloseIcon 
            onClick={onDeletePress} 
            scale={scale}
          >
            <svg viewBox="0 0 40 40">
              <path  d="M 10,10 L 30,30 M 30,10 L 10,30" />
            </svg>
          </CloseIcon>
          <ScaleHandlerBottomLeft
            style={{ transform: `scale(${1/scale})`}}
            onMouseDown={(e) => {
            onSetMode("transform") 
            setHandlerRotationOffset(-3/4 * Math.PI)
            setMouseStartingPoint({
              x:e.clientX,
              y:e.clientY
              })
            }}
          /> 
        </>
      }
    </ActiveImageWrapper>
  );
};

export default ActivePattern;

