
import BorderImg1 from'./borders/1820_aussparung.png'
import BorderImg2 from'./borders/1830_aussparung.png'
import BorderImg3 from'./borders/1890_aussparung.png'
import BorderImg4 from'./borders/1930_aussparung.png'
import BorderImgButterick from'./borders/1950_aussparung.png' 
import BorderImgMcqueen from'./borders/alexander_mcqueen_aussparung.png'
import BorderImgChanel from'./borders/chanel_1920_aussparung.png'
import BorderImgShuting from'./borders/shuting_qui_aussparung.png'
import BorderImgThebe from'./borders/thebe_magugu_aussparung.png'
import BorderImgTokyo from'./borders/tokyo_james_aussparung.png'

const borders =  {
  "frack":BorderImg1,
  "biedermeier": BorderImg2,
  "fahrradkostuem": BorderImg3,
  "knickerbocker":BorderImg4,
  "butterick":BorderImgButterick,
  "cocochanel":BorderImgChanel,
  "mcqueen":BorderImgMcqueen,
  "shutingqiu":BorderImgShuting,
  "thebemagugu":BorderImgThebe,
  "tokyojames":BorderImgTokyo
}

export default borders