import OverlayImg1 from'./overlays/1820_milchglas.png'
import OverlayImg2 from'./overlays/1830_milchglas.png'
import OverlayImg3 from'./overlays/1890_milchglas.png'
import OverlayImg4 from'./overlays/1930_milchglas.png'
import OverlayImgButterick from'./overlays/1950_milchglas.png' 
import OverlayImgMcqueen from'./overlays/alexander_mcqueen_milchglas.png'
import OverlayImgChanel from'./overlays/chanel_1920_milchglas.png'
import OverlayShuting from'./overlays/shuting_qui_milchglas.png'
import OverlayImgThebe from'./overlays/thebe_magugu_milchglas.png'
import OverlayImgTokyo from'./overlays/tokyo_james_milchglas.png'

const overlays =  {
  "frack":OverlayImg1,
  "biedermeier": OverlayImg2,
  "fahrradkostuem": OverlayImg3,
  "knickerbocker":OverlayImg4,
  "butterick":OverlayImgButterick,
  "cocochanel":OverlayImgChanel,
  "mcqueen":OverlayImgMcqueen,
  "shutingqiu":OverlayShuting,
  "thebemagugu":OverlayImgThebe,
  "tokyojames":OverlayImgTokyo
}

export default overlays