import silhouettes from '../images/entwerfen/silhouettes.js'
import drawings from '../images/entwerfen/drawings.js'
import overlays from '../images/entwerfen/overlays.js'
import borders from '../images/entwerfen/borders.js'

 const figurettenData = [
  {
    title:"Frack mit „Kosaken“ Hose",
    year:"späte 1820er",
    infoBox:{
      title:"",
      text:"Inspiriert vom Besuch des russischen Zaren Alexander&nbspI., der mit seinen kosakischen Soldaten London besucht hatte, entstand der Modeentwurf einer sehr großzügig geschnittenen Hose. Diese unterschied sich deutlich vom klassischen engen Hosenschnitt. Die an der Taille geraffte Kosaken-Hose entwickelte sich in den kommenden Jahren zu einem beliebten Kleidungsstück der europäischen Männermode."
    },
    img:silhouettes.frack,
    drawing:drawings.frack,
    overlay:overlays.frack,
    border:borders.frack,
  },
  {
    title:"Biedermeier Kleid",
    year:"1830",
    infoBox:{
      title:"",
      text:"Die Damenmode ab den 1820er Jahren zeigte sich im Gegensatz zum großzügigen Empireschnitt des vorigen Jahrzehnts wesentlich beengter und konservativer. Sowohl Korsett als auch Krinoline setzten sich lange Zeit durch, und auch die Ballonärmel blieben für den Rest des Jahrhunderts ein charakteristischer Bestandteil der europäischen Damenmode."
    },
    img:silhouettes.biedermeier,
    drawing:drawings.biedermeier,
    overlay:overlays.biedermeier,
    border:borders.biedermeier,
  },
  {
    title:"Fahrradkostüm",
    year:"1895",   
    infoBox:{
      title:"",
      text:"Im späten 19. Jahrhundert zogen immer mehr junge Frauen in die Großstädte, um dort ihren eigenen Unterhalt zu verdienen. Die neu gewonnenen Freiheiten zeigten sich auch im Bekleidungsstil: von der Freizeitmode wie dem hier zu sehenden Fahrradkostüm bis zum Aufkommen der Bluse als eigenständiges Kleidungsstück."
    },
    img:silhouettes.fahrradkostuem,
    drawing:drawings.fahrradkostuem,
    overlay:overlays.fahrradkostuem,
    border:borders.fahrradkostuem,
  },
  {
    title:"Knickerbocker Hosen",
    year:"1920",
    infoBox:{
      title:"",
      text:"Seit dem späten 18. Jahrhundert gehört der klassische Anzug zum Standard der Herrenmode, die sich bis heute nur geringfügig verändert hat. Die Knickerbocker, oder auch Kniebundhose, stellt vor diesem Hintergrund eine revolutionäre Neuerung dar. König Eduard VIII. von England sorgte mit seiner Vorliebe für den legeren Stil dafür, dass solcherart Sportbekleidung die Alltagsmode eroberte."
    },
    img:silhouettes.knickerbocker,
    drawing:drawings.knickerbocker,
    overlay:overlays.knickerbocker,
    border:borders.knickerbocker,
  },
  {
    title:"Coco Chanel Ensemble",
    year:"1926",
    infoBox:{
      title:"",
      text:"Nach dem Ersten Weltkrieg demonstrierten Frauen ihre neuen Freiheiten auch in der Mode: durch tiefer sitzende Taillen und vergleichsweise einfache Schnitte. Sie legten das Korsett endgültig ab und ließen sich die Haare kurz schneiden. Die Modeschöpferin Coco Chanel trug mit ihren Entwürfen dazu bei, das Bild der neuen unabhängigen Frau, der sogenannten Garçonne, zu prägen."
    },
    img:silhouettes.cocochanel,
    drawing:drawings.cocochanel,
    overlay:overlays.cocochanel,
    border:borders.cocochanel,
  },
  {
    title:"Butterick",
    year:"1953",
    infoBox:{
      title:"",
      text:"Die Wiederkehr enger Mieder und bauschiger Unterröcke fiel nicht zufällig mit dem Ende des Zweiten Weltkriegs zusammen. Im Gegensatz zu den strengen Linien der 1940er-Jahre zeugte der üppige Stoffverbrauch für einen Tellerrock vom aufkommenden Wohlstand der Nachkriegsjahre. Zudem ist die betont weibliche Mode Ausdruck für ein traditionelles Rollenverständnis."
    },
    img:silhouettes.butterick,
    drawing:drawings.butterick,
    overlay:overlays.butterick,
    border:borders.butterick,
  },
  {
    title:"Alexander McQueen",
    year:"2009",
    infoBox:{
      title:"",
      text:"1993 gründete Alexander McQueen das nach ihm benannte Fashion-Label in London. Seine aufsehenerregenden und exzentrischen Modenschauen hinterließen in der Welt der Haute Couture einen bleibenden Eindruck. Nach dem Tod des Designers 2010 übernahm Sarah Burton die Rolle der kreativen Leitung der Modemarke."
    },
    img:silhouettes.mcqueen,
    drawing:drawings.mcqueen,
    overlay:overlays.mcqueen,
    border:borders.mcqueen,
  },
  {
    title:"Thebe Magugu",
    year:"2018",
    infoBox:{
      title:"",
      text:"Als erster afrikanischer Designer erhielt Thebe Magugu 2019 den Preis der Louis Vuitton Stiftung (LVMH-Preis) für seine Kollektion „Home Economics“. Der junge Künstler setzt sich in seinen eleganten Kollektionen mit der Kultur und Politik Südafrikas auseinander."
    },
    img:silhouettes.thebemagugu,
    drawing:drawings.thebemagugu,
    overlay:overlays.thebemagugu,
    border:borders.thebemagugu,
  },
  {
    title:"Shuting Qiu",
    year:"2019",
    infoBox:{
      title:"",
      text:"Für ihre Kollektionen lässt sich die gebürtige Chinesin Shuting Qiu von ihren Reisen um die ganze Welt inspirieren. Die Designerin erschafft eine ungewöhnliche Mischung aus verschiedenen Mustern, fröhlichen Farben und experimentellen Schnitten. Asymmetrie und das Aufbrechen klassischer Silhouetten bestimmen die Linie ihrer Modemarke, die sie kurz nach ihrem Studium in Belgien, unter dem renommierten Walter Van Beirendonck der „Antwerp Six“ gründete."
    },
    img:silhouettes.shutingqiu,
    drawing:drawings.shutingqiu,
    overlay:overlays.shutingqiu,
    border:borders.shutingqiu,
  },
  {
    title:"Tokyo James",
    year:"2021",
    infoBox:{
      title:"",
      text:"Eine Kombination klassischer britischer Herrenschnitte mit traditionellen nigerianischen Techniken und Textilien kennzeichnet die Mode des Designers Tokyo James. Der nigerianisch-britische Designer zog 2015 von London nach Lagos, Nigeria, wo er sein gleichnamiges Herrenmode-Label gründete."
    },
    img:silhouettes.tokyojames,
    drawing:drawings.tokyojames,
    overlay:overlays.tokyojames,
    border:borders.tokyojames,
  },
 
]

export default figurettenData