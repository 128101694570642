import React, {useState} from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  position:relative;
  box-shadow: 10px 0px 22px #0000000D;
  width:57px;
  height:57px;
  background-color: black;
  display:flex;
  justify-content: center;
  align-items: center;
  cursor:pointer;
  &:hover{
    width:245px;
    background-color: white;
  }
  transition: width 0.3s ease-in-out;
`

const DownloadIcon = styled.svg`
  stroke:${props => props.hover ? '#000' : '#ffffff'}; 
  height:${props => props.hover ? '33' : '40'}px; 
  width:${props => props.hover ? '33' : '40'}px; 
  transition: all 0.5s ease-in-out;
  position:absolute;
  left:10px;
`

const Text = styled.p`
  display:${props => props.hover ? 'block' : 'none'}; 
  margin-left: 50px;
  text-transform: uppercase;
` 

const DownloadButton = ({onDownloadClick,text}) => {
  const [hover, setHover] = useState(false)
  return (
    <Wrapper 
      hover={hover}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onDownloadClick}
    >
      <DownloadIcon 
        hover={hover}
        xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" stroke-width="1.5" fill="none" stroke-linecap="round" stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
        <line x1="12" y1="5" x2="12" y2="19" />
        <line x1="18" y1="13" x2="12" y2="19" />
        <line x1="6" y1="13" x2="12" y2="19" />
        <line x1="6" y1="22" x2="18" y2="22" />
      </DownloadIcon>
      <Text hover={hover}>{text}</Text>
    </Wrapper>
  )
}

export default DownloadButton