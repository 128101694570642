
import SilhouetteImg1 from'./silhouettes/1820_papierschnitt.png'
import SilhouetteImg2 from'./silhouettes/1830_papierschnitt.png'
import SilhouetteImg3 from'./silhouettes/1890_papierschnitt.png'
import SilhouetteImg4 from'./silhouettes/1930_papierschnitt.png'
import SilhouetteButterick from'./silhouettes/1950_papierschnitt.png'
import SilhouetteMcqueen from'./silhouettes/alexander_mcqueen_papierschnitt.png'
import SilhouetteChanel from'./silhouettes/chanel_1920_papierschnitt.png'
import SilhouetteShuting from'./silhouettes/shuting_qui_papierschnitt.png'
import SilhouetteThebe from'./silhouettes/thebe_magugu_papierschnitt.png'
import SilhouetteTokyo from'./silhouettes/tokyo_james_papierschnitt.png'

const sillouhettes =  {
  "frack":SilhouetteImg1,
  "biedermeier": SilhouetteImg2,
  "fahrradkostuem": SilhouetteImg3,
  "knickerbocker":SilhouetteImg4,
  "cocochanel":SilhouetteChanel,
  "butterick":SilhouetteButterick,
  "mcqueen":SilhouetteMcqueen,
  "shutingqiu":SilhouetteShuting,
  "thebemagugu":SilhouetteThebe,
  "tokyojames":SilhouetteTokyo
}
  
;

export default sillouhettes